import { Box } from 'atomic-layout';
import Link from 'next/link';
import format from 'date-fns/format';
import styled from 'styled-components';
import NextImage from "next/legacy/image";
import { H4, Paragraph } from 'components/atoms/Text';
import { BlogCard } from '../atoms/Cards';
import { BlogCardDivider } from '../atoms/Dividers';
import { Button } from '../atoms/Buttons';
import { lighten } from 'polished';

const StyledBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.colors.shadow,
  [`@media screen and (max-width: ${theme.breakpoints.sm.maxWidth})`]: {
    flexDirection: 'column',
  },
  overflowX: 'scroll',
  scrollbarWidth: 'none',

  ['&::-webkit-scrollbar']: {
    display: 'none',
  }
}));


const LinkText = styled.p(({theme}) => ({
  color: theme.colors.primary,
  cursor: 'pointer',
  margin: 'initial',
  padding: 'initial',
  ['&:hover']: {
    color: lighten(0.05, theme.colors.primary),
    textDecoration: 'underline',
  }
}));

export default function LatestBlog({ blogs }) {
  return (
    <div style={{ width: '100%' }}>
      <StyledBox
        flex
        justifyContent="space-between"
        alignItems="center"
        gap="2rem"
      >
        {blogs &&
          blogs.map((blog) => {
            return (
              <Link
                key={blog.attributes.slug}
                href={`/blog/${blog.attributes.slug}`}
                passHref
                legacyBehavior>
                <BlogCard>
                  <NextImage
                    src={blog.attributes.thumbnailImage?.data?.attributes?.url}
                    alt={
                      blog.attributes.thumbnailImage?.data?.attributes
                        ?.alternativeText
                    }
                    width={1920}
                    height={1080}
                    objectFit={'cover'}
                  />
                  <H4 $ellipsis>{blog.attributes.title}</H4>
                  <Paragraph $ellipsis $padding="0" $lineHeight={0.8}>
                    {blog.attributes.excerpt}
                  </Paragraph>
                  <BlogCardDivider />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button $primary $padding="0.2rem 1.5rem">
                      Read Now
                    </Button>
                    <span itemProp="datePublished">
                      {format(
                        blog.attributes.createdAt
                          ? Date.parse(blog.attributes.createdAt)
                          : new Date(),
                        'MMMM do, yyyy'
                      )}
                    </span>
                  </div>
                </BlogCard>
              </Link>
            );
          })}
      </StyledBox>
      <Box flex justifyContent="space-between" padding="2rem 0">
        <Link href="/blog" legacyBehavior>
          <LinkText>Read More from SatsGoal »</LinkText>
        </Link>
      </Box>
    </div>
  );
}
