import { Box } from 'atomic-layout';
import styled from 'styled-components';

export const HeroDivider = styled(Box)`
  padding: initial;
`;

export const BTCTextDivider = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-left: 1.5rem;
  min-height: 35px;
`;

export const BlogCardDivider = styled.hr`
  // border-top: ${({ theme }) =>
    `thin solid ${theme.colors.primaryLowOpacity}`};
  // width: 100%;
  margin: 0;
  padding: 0;

  border: 0;
  height: 2px;
  background-image: ${({ theme }) =>
    `linear-gradient(to right, transparent, ${theme.colors.primaryLowOpacity}, transparent)`};
`;
